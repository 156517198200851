import React from "react"

import Layoutde from "../../components/layoutde/layoutde"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Clientlogos from "../../components/clientlogos/clientlogos"
import Footer from "../../components/footer/footer"

import "../index.scss"
import "../about-us.scss"

import ImgAboutUs13 from "../../images/about-us/about-us-13.jpg"
import ImgAboutUs14 from "../../images/about-us/about-us-14.jpg"
import ImgAboutUs15 from "../../images/about-us/about-us-15.jpg"
import ImgAboutUs16 from "../../images/about-us/about-us-16.jpg"
import ImgAboutUs17 from "../../images/about-us/about-us-17.jpg"
import ImgAboutUs20 from "../../images/about-us/about-us-20.jpg"
import ImgAboutUs21 from "../../images/about-us/about-us-21.jpg"
import ImgAboutUs23 from "../../images/about-us/about-us-23.jpg"

import ImgAboutUsReference1 from "../../images/client-logo/slsp.svg"
import ImgAboutUsReference2 from "../../images/client-logo/peugeot.svg"
import ImgAboutUsReference3 from "../../images/client-logo/innogy.svg"

import VideoBg from "../../videos/ftrs-about-us.mp4"

const WirUberUns = () => (

    <Layoutde>
        <SEO title="Wir über uns" description="Wir sind eine Gruppe von Geeks mit viel Erfahrung in der Entwicklung digitaler Produkte." />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content">

            <Row>
                <Col xs="12" lg="8" xl="6" className="pb-5">
                    <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Unsere Web- und&nbsp;mobile Apps können sich sehen lassen.</h1>

					<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Vertrauen</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Seit mehr als&nbsp;10&nbsp;Jahren erstellen wir Web- und&nbsp;Mobile Apps, die&nbsp;von&nbsp;marktführenden Unternehmen in&nbsp;verschiedenen Branchen in&nbsp;großer Zahl eingesetzt werden.</p>

                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Erfahrene Technologie-Experten</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Im Laufe der&nbsp;Entwicklung verschiedener Produkte haben wir viele technologische Herausforderungen gelöst und&nbsp;das&nbsp;Wissen und&nbsp;die&nbsp;Erfahrung gewonnen, um&nbsp;neue Herausforderungen schnell und&nbsp;erfolgreich zu&nbsp;meistern.</p>

					<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Transparenz</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Wir behalten Kommunikation, Prozesse und&nbsp;Reporting transparent, damit Sie immer wissen, was gerade los&nbsp;ist.</p>

                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Persönlicher Projektleiter</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Holen Sie sich die&nbsp;Aufmerksamkeit, die&nbsp;Sie brauchen. Mit&nbsp;einem direkten Ansprechpartner auf&nbsp;unserer Seite haben Sie immer eine&nbsp;schnelle Antwort auf&nbsp;Ihre Fragen und&nbsp;Wünsche. Unser flexibles Team aus&nbsp;erfahrenen Entwicklern ist bereit, den&nbsp;Zeitplan anzupassen und&nbsp;die&nbsp;Kapazität in&nbsp;kürzester Zeit zu&nbsp;erweitern.</p>

                </Col>
                <Col xs="12" lg="4" xl="6" className="pb-5">
                    <div className="ftrs-sidepool">
                        <div className="ftrs-sidepool__container">
                        <div className="ftrs-sidepool__container">
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs15} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs14} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs13} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs16} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs17} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs20} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs23} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs21} className="ftrs-sidepool__img" alt="" />
                            </div>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>

        <Clientlogos lang='de'></Clientlogos>

        <Container>
            <div className="ftrs-testimonials">
                <Row>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference1} className="ftrs-testimonials__img ftrs-testimonials__img--horizontal" alt="" />
                            <p className="ftrs-testimonials__name">Slovenská sporiteľňa</p>
                            <p className="mt-0 mb-0">ein Mitglied der&nbsp;Erste&nbsp;Group&nbsp;Bank&nbsp;AG</p>
                            <p className="mt-0 mb-0">die größte Geschäftsbank in&nbsp;der&nbsp;Slowakei</p>
                        </div>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">"Die Hauptgründe, warum wir auch in&nbsp;den&nbsp;nächsten Jahren mit&nbsp;feedTRACK arbeiten werden, sind die&nbsp;hohe Fragebogen-Rücklaufquote, die&nbsp;übersichtliche Benutzeroberfläche und&nbsp;vor&nbsp;allem die&nbsp;Flexibilität – beim&nbsp;Datentransfer (die&nbsp;Agentur versteht etwas von&nbsp;Daten und&nbsp;IT im&nbsp;Allgemeinen), bei&nbsp;der&nbsp;Struktur von&nbsp;Fragebögen und&nbsp;E-Mails (ganz nach&nbsp;unseren Anforderungen), bei&nbsp;der&nbsp;Aufgabennachverfolgung oder bei&nbsp;der&nbsp;Analyse einzelner Filialen, Bereiche und&nbsp;Regionen.</p>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">Wenn wir den&nbsp;Hauptgrund nennen müssten, warum feedTRACK bei&nbsp;uns gewonnen hat, lässt sich dies in&nbsp;einem Wort ausdrücken: Professionalität."</p>
                    </Col>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference2} className="ftrs-testimonials__img ftrs-testimonials__img--vertical" alt="" />
                            <p className="ftrs-testimonials__name">P Automobil Import</p>
                            <p className="mt-0 mb-0">offizieller Peugeot-Importeur</p>
                        </div>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">"Uns gefällt, dass AUTO&nbsp;eCIM ein&nbsp;benutzerfreundliches und&nbsp;praktisches Online-System ist. Dank wöchentlicher Aktualisierungen haben wir stets einen&nbsp;detaillierten Überblick über&nbsp;Neuigkeiten am&nbsp;Markt."</p>
                    </Col>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference3} className="ftrs-testimonials__img ftrs-testimonials__img--vertical" alt="" />
                            <p className="ftrs-testimonials__name">Innogy</p>
                            <p className="mt-0 mb-0">Mitglied von&nbsp;E.ON</p>
                        </div>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">"dMONITOR ist ein&nbsp;nützliches Werkzeug für&nbsp;uns. Dank dMONITOR haben wir nun&nbsp;einen umfassenden Überblick über&nbsp;die&nbsp;Kommunikationsaktivitäten auf&nbsp;dem&nbsp;tschechischen Energiemarkt, der&nbsp;uns bisher im&nbsp;Bereich der&nbsp;direkten Kommunikation gefehlt hat.</p>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">Wir schätzen die&nbsp;benutzerfreundliche Internet-App mit&nbsp;ihren umfangreichen Filtermöglichkeiten in&nbsp;einem reichhaltigen Archiv."</p>
                    </Col>
                </Row>

            </div>
        </Container>

		<Footer lang='de'></Footer>

    </Layoutde>

)

export default WirUberUns